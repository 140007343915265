import debounce from 'lodash.debounce';
import BaseFilter from '../modules/filters/baseFilter';

class ProjectsSection {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.filter = this.root.querySelector('[data-filter-switcher]');
        this.projectsEl = this.root.querySelector(
            '[data-projects-content-projects]'
        );
        this.newsSliderEl = this.root.querySelector(
            '[data-project-news-slider]'
        );
        this.loader = this.root.querySelector(
            '[data-projects-list-loader]'
        );

        this.filterInstance = null;
        this.newsSlider = null;
        this.projectType = null;

        this.getProjects = debounce(this.loadProjects, 500);

        return true;
    }

    init() {
        this.initFilters();
    }

    initFilters() {
        if (!this.filter) return;
        this.filterInstance = new BaseFilter(this.filter, this.root);
        this.root.addEventListener('filter-switcher-change', event =>
            this.onFilterChange(event)
        );

        this.filterInstance.toggleClick(0);
    }

    onFilterChange(event) {
        const { detail } = event;
        const { value } = detail;

        this.projectType = value;

        if (value === 'passed') {
            if (this.newsSliderEl) this.newsSliderEl.style.display = 'none';
        } else {
            if (this.newsSliderEl) this.newsSliderEl.style.display = '';
        }

        this.loadProjects();
    }

    loadProjects() {
        const formData = new FormData();
        formData.append('action', 'get_projects_ajax');
        this.loader.classList.remove('d-none');

        if (this.projectType) formData.append('project_type', this.projectType);

        $.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { html } = data;

                this.projectsEl.innerHTML = html;
            }.bind(this),
            error: function () {}.bind(this),
            complete: function () {
                this.loader.classList.add('d-none');
            }.bind(this),
        });
    }
}

const sections = document.querySelectorAll('[data-projects]');
const initProjects = sections => {
    if (!sections) return;

    sections.forEach(el => {
        new ProjectsSection(el);
    });
};

initProjects(sections);
